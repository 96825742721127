.hero {
    background-image: url("../imgs/home_bg.jpg");
    height:80vh;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position:bottom;
  }

.hero-mobile {
    display:none;
}

@media (min-width: 1440px) {
  .hero {
    background-image: url("../imgs/home_bg.jpg");
    height:100vh;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .card:hover {
    transform: scale(1);
  }
}


@media (max-width: 1080px) {
    .hero {
      background-image: none;
      height:auto;
      background-repeat: no-repeat;
      background-size: 100%;
    }
    .hero-mobile {
      display:block;
      width:100%;
    }
}

.card {
  cursor: pointer;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 8px 8px -5px lightgrey;
}

.starter-container {
  max-height: 600px;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0;
  position: relative;
}

.starter-img {
  width: 100%;
}

.starter-text-btn-block {
  position: absolute;
  bottom: 15%;
  left: 10%;
  width: 80%;
  color: white;
  z-index: 99;
  display: flex;
  flex-direction: column;
}

.starter-text-main {
 font-size: 40px;
 font-weight: 300;
}

.starter-text-sub {
  font-size: 20px;
  background-color: rgba(0,0,0,0.5);
  width: fit-content;
  padding: 2px;
  border-radius: 5px;
  margin-bottom: 2px;
}

.starter-btn {
  background-color: #F8822C;
  font-weight: 500;
  width: 20%;
  border-radius: 5px;
  margin-top: 8px;
}

.starter-btn:hover {
  background-color: #FFDD03;
}

@media (max-width: 600px) {

  .starter-text-btn-block {
    bottom: 5%;
    left: 5%;
    width: 90%;
  }

  .starter-text-main {
    font-size: 30px;
    font-weight: 300;
  }

  .starter-text-sub {
    font-size: 15px;
  }

  .starter-btn {
    width: 100%;
  }
}
