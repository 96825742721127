.navigation-bar {
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.login-register-block {
    cursor: pointer;
    font-size: 20px;
    font-weight: 300;
}

.icon {
    cursor: pointer;
    font-size: 40px;
    font-weight: 500;
}