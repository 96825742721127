.cookie {
    z-index: 9999;
    background-color: #FAFAFA;
}

.btn-primary {
    background-color: #FBC403;
}

.btn-primary:hover {
    background-color: #FFDD03;
}