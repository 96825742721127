@font-face {
    font-family: 'Noto Sans SC';
    src: url(../fonts/NotoSansSC-Thin.otf);
    font-weight: 100;
}

@font-face {
    font-family: 'Noto Sans SC';
    src: url(../fonts/NotoSansSC-Light.otf);
    font-weight: 300;
} 

@font-face {
    font-family: 'Noto Sans SC';
    src: url(../fonts/NotoSansSC-Regular.otf);
    font-weight: 400;
} 

@font-face {
    font-family: 'Noto Sans SC';
    src: url(../fonts/NotoSansSC-Medium.otf);
    font-weight: 500;
}

/* Updating input placeholder text font weight to be light*/
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-family: 'Noto Sans SC', sans-serif;
  font-size: 1rem; 
  font-weight: 100; 
  line-height: 1.25; 
}
::-moz-placeholder { /* Firefox 19+ */
  font-family: 'Noto Sans SC', sans-serif;
  font-size: 1rem; 
  font-weight: 100; 
  line-height: 1.25; 
}
:-ms-input-placeholder { /* IE 10+ */
  font-family: 'Noto Sans SC', sans-serif;
  font-size: 1rem; 
  font-weight: 100; 
  line-height: 1.25; 
}
:-moz-placeholder { /* Firefox 18- */
  font-family: 'Noto Sans SC', sans-serif;
  font-size: 1rem; 
  font-weight: 100; 
  line-height: 1.25; 
}

html,body,p,a,span,.btn {
  /* default font-size is 16px - this is set in the default browser stylesheet */ 
  font-family: 'Noto Sans SC', sans-serif;
  font-size: 1rem; 
  font-weight: 100; 
  line-height: 1.5; 
} 

small .text-small{
  font-family: 'Noto Sans SC', sans-serif;
  font-weight: 100;
  font-size: 0.875rem;    
}

h1 {
  font-family: 'Noto Sans SC', sans-serif;
  font-weight: 300;
  font-size: 2.25rem;
}

h2 {
  font-family: 'Noto Sans SC', sans-serif;
  font-weight: 300;
  font-size: 2rem;
}

h3 {
  font-family: 'Noto Sans SC', sans-serif;
  font-weight: 300;
  font-size: 1.75rem;
}

h4 {
  font-family: 'Noto Sans SC', sans-serif;
  font-weight: 300;
  font-size: 1.5rem;
}

h5 {
  font-family: 'Noto Sans SC', sans-serif;
  font-weight: 300;
  font-size: 1.25rem;
}


/* Decrease all font sizes on mobile */ 
@media (max-width: 767px) { 
  html,body,p,a,span,.btn { 
    /* default is 1rem or 16px */ 
    font-size: 1rem; 
    font-weight: 300;
  }
    
    h1 {
      font-family: 'Noto Sans SC', sans-serif;
      font-weight: 400;
      font-size: 1.875rem;
    }

    h2 {
      font-family: 'Noto Sans SC', sans-serif;
      font-weight: 400;
      font-size: 1.75rem;
    }

    h3 {
      font-family: 'Noto Sans SC', sans-serif;
      font-weight: 400;
      font-size: 1.5rem;
    }

    h4 {
      font-family: 'Noto Sans SC', sans-serif;
      font-weight: 300;
      font-size: 1.25rem;
    }
    
    h5 {
      font-family: 'Noto Sans SC', sans-serif;
      font-weight: 300;
      font-size: 1.125rem;
    }
}

.brand-primary-color {
  color: #F8822C;
}

.brand-secondary-color {
  color: #EC7144;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
/*  color: #DDDDDD;*/
  opacity: 1; /* Firefox */
}

.btn:focus {
  outline: none!important;
  box-shadow: none!important;
}

.btn-primary,.btn-primary:focus {
    background-color:#43B0B1!important;
    color:#FAFAFA!important;
    border-radius: 4px;
    border: 1px solid #43B0B1;
    padding: 8px 16px!important;
}

.btn-secondary,.btn-secondary:focus {
  background-color:#FFFFFF!important;
  color:#43B0B1!important;
  border-radius: 4px;
  border: 1px solid #43B0B1;
  padding: 8px 16px!important;
  box-shadow:none!important;
}

.btn-primary:hover,.btn-secondary:hover {
    background-color:#1B8586!important;
    color:#FAFAFA!important;
    border: 1px solid #1B8586;
} 

.btn-third,.btn-third:focus{
    color:#43B0B1!important;
    border-radius: 4px;
    padding: 8px 0px!important;
}
  
.btn-third:hover{
    color:#1B8586!important;
}

.btn-forth{
  color:#848F90!important;
  border-radius: 4px;
  padding: 8px 0px!important;
}

.btn-forth:hover{
  background-color:#F4FCFE!important;
  color:#43B0B1!important;
}

.btn-forth:focus{
  color:#43B0B1!important;
}

.btn-secondary, .btn-secondary:focus {
    background-color: #FFFFFF!important;
    color: #43B0B1!important;
    border-radius: 4px;
    border: 1px solid #43B0B1;
    padding: 8px 16px!important;
}


.nav-link .nav-link:focus {
    color: #F8822C!important;
}
  
.nav-link:hover{
    color: black!important;
    box-shadow: 0px 2px 0px 0px black!important;
}
  
.nav-link.active {
    color: black!important;
    box-shadow: 0px 2px 0px 0px black!important;
}

.grey-border {
  border: 1px solid #E7E9EE;
  border-radius: 8px;
}

.grey-box {
  background-color:#FAFAFA;
}

.text-dark-green {
  color: #114B5F;
}

.overflow-x{
  min-width:375px;
  overflow-x: scroll;
}

.overflow-y {
  max-height:400px;
  overflow-y: scroll;
}

.search-result-line {
  background-color:#FFFFFF;
  padding-bottom: 4px;
}

.search-result-line:hover{
  background-color:#FAFAFA;
  border-radius: 4px;
  box-shadow: 0px 2px 4px 0px #DDDDDD!important;
  cursor:pointer;
}

.ratio {
  --bs-aspect-ratio: 66%;
}

@media (min-width: 1440px) {
  .px-lg-10 {
    padding-left: 240px!important;
    padding-right: 240px !important;
  }
}

@media (min-width: 1080px) and (max-width: 1440px) {
    .px-lg-10 {
        padding-left: 120px;
        padding-right: 120px;
    }
    .hide-desktop {
        display:none!important;
    }
}

@media (max-width: 1080px) {
    .hide-mobile {
        display:none!important;
    }
    .nav-link:hover{
        color: black!important;
        background-color:#F4FCFE;
        box-shadow: none;
    }
    .nav-link.active {
        color: black!important;
        background-color:#F4FCFE;
        box-shadow: none;
    }
    
}